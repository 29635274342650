<template>
  <div>
    <div class="user-posting">
        <b-row>
          <b-col sm="12"><h5>Create your Troupe Portfolio</h5></b-col>
        </b-row>    
        <b-row>
          <b-col sm="8 mt-2"><p>Publish your professional Troupe details with complete contact address and a profile picture. </p></b-col>
          <b-col sm="4 mt-2"><b-button pill type="submit" variant="warning"><router-link :to="{name:'troupes',params:{ Maincatg:'All'}}">View All Troupes</router-link></b-button></b-col>
        </b-row>
    </div>    
    <b-col cols="12 my-4">      
              <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
                <b-form-group id="input-group-2" label-for="Troupe_name" class="col-8">
                  <b-form-input
                    id="Troupe_name"
                    v-model="InpForm.Troupe_name"
                    placeholder="Troupe/Band Name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="Prog_Name" class="col-8">
                  <b-form-input
                    id="Prog_Name"
                    v-model="InpForm.Prog_Name"
                    placeholder="Troupe/band title"
                    required
                  ></b-form-input>                
                </b-form-group>

                <RefFormCatg :ShowMainCatg="true" @EmitMainCatg="SetMainCatg" :key="ComponentKey +1"/>
             
                <b-form-group class="col-8">
                <b-form-textarea
                  id="Address"
                  size="md"
                  v-model="InpForm.troupe_address"
                  placeholder="Troupe/band Address"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>   
                </b-form-group>

                <RefFormPlaces :ShowCountry="true" @EmitCountry="SetCountry" @EmitState="SetState" @EmitCity="SetCity" :key="ComponentKey +2"/>

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="troupe_cntct1">
                  <b-form-input
                    id="troupe_cntct1"
                    v-model="InpForm.troupe_cntct1"
                    placeholder="Contact no #1"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_cntct2">
                  <b-form-input
                    id="troupe_cntct2"
                    v-model="InpForm.troupe_cntct2"
                    placeholder="Contact no #2"
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

                <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="troupe_email">
                  <b-form-input
                    id="troupe_email"
                    v-model="InpForm.troupe_email"
                    @change="FindNewUserEmailID()"
                    placeholder="Your Email ID"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_website" >
                  <b-form-input
                    id="troupe_website"
                    v-model="InpForm.troupe_website"
                    placeholder="Your Website link"
                    required
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_facebook">
                  <b-form-input
                    id="troupe_facebook"
                    v-model="InpForm.troupe_facebook"
                    placeholder="Your Facebook link"
                    required
                  ></b-form-input>                
                </b-form-group>
              </b-form>              

              <b-form inline class="col-12 mt-2">
                <b-form-group id="input-group-2" label-for="troupe_instagram">
                  <b-form-input
                    id="troupe_instagram"
                    v-model="InpForm.troupe_instagram"
                    placeholder="Your Instagram link"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_youtube">
                  <b-form-input
                    id="troupe_youtube"
                    v-model="InpForm.troupe_youtube"
                    placeholder="Your Youtube link"
                  ></b-form-input>                
                </b-form-group>

                <b-form-group id="input-group-2" label-for="troupe_twitter">
                  <b-form-input
                    id="troupe_twitter"
                    v-model="InpForm.troupe_twitter"
                    placeholder="Your Twitter link"
                  ></b-form-input>                
                </b-form-group>                
              </b-form>

                <b-form-group class="col-8 mt-2">
                  <b-form-textarea
                    id="troupe_description"
                    size="md"
                    v-model="InpForm.troupe_description"
                    placeholder="Troupe Details"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>     


                <b-form-group class="col-8 mt-2">
                    <b-form-file
                      v-model="file1"
                      :state="Boolean(file1)"
                      placeholder="Choose a cover picture..."
                      drop-placeholder="or Drop file here..."
                      @change="previewImage" accept="image/*" 
                    ></b-form-file>
                </b-form-group>                               

                <!-- <b-row class="my-3">                  
                  <b-col cols="12">         
                    <b-button class="float-left ml-3" variant="success"  @click="click1"> {{ButtonText}}
                    </b-button>
                    <input type="file" ref="input1"
                      style="display: none"
                      @change="previewImage" accept="image/*" >                                        
                  </b-col>                  
                </b-row>

                <b-row>
                  <b-col cols="12 my-3 ml-3" >                    
                    <b-img v-if="ShowPhoto" class="float-left" rounded height="80" width="80" :src="PhotoPreview" >                 
                    </b-img>
                      <b-spinner v-else-if="ShowSpinner" small
                        v-for="variant in variants"
                        :variant="variant"
                        :key="variant"
                      ></b-spinner>                
                  </b-col>  
                </b-row>                -->

                <!-- <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_programs"
                  size="md"
                  v-model="InpForm.troupe_programs"
                  placeholder="Program Description"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_artists"
                  size="md"
                  v-model="InpForm.troupe_artists"
                  placeholder="Artist details"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_activities"
                  size="md"
                  v-model="InpForm.troupe_activities"
                  placeholder="Troupe Activities"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>                

                <b-form-group class="col-8 mt-2">
                <b-form-textarea
                  id="troupe_achievement"
                  size="md"
                  v-model="InpForm.troupe_achievement"
                  placeholder="Troupe Achievements"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
                </b-form-group>  -->
                              
                <!-- <b-form-checkbox class="col-8 mt-2"
                  id="checkbox-1"
                  v-model="InpForm.premium_ind"
                  name="checkbox-1"
                  value="Y"
                  unchecked-value="N"
                >
                  Premium Profile?
                </b-form-checkbox>      -->

                <!-- Media Section starts here >> users_troupe_media -->
                 <!-- <b-form-group  id="input-group-2" label-for="" class="col-8 mt-2">
                  <b-form-input
                    id="Video1"
                    v-model="InpForm.Video1"
                    placeholder="Video1"
                  ></b-form-input>
                  <b-form-input 
                    id="Video2"
                    v-model="InpForm.Video2"
                    placeholder="Video2"
                  ></b-form-input>
                  <b-form-input
                    id="Brochure"
                    v-model="InpForm.Brochure"
                    placeholder="Brochure"
                  ></b-form-input>
                  <b-form-input
                    id="Biodata"
                    v-model="InpForm.Biodata"
                    placeholder="Biodata"
                  ></b-form-input>
                </b-form-group>               -->

                <b-button pill type="submit" variant="primary">Submit</b-button>
                <b-button pill type="reset" variant="danger">Cancel</b-button>
              </b-form>        
    </b-col>
    <b-modal v-model="modalShow" @ok="SendLoginLink"><b-alert variant="success" show>Your email id {{RespEmailID}} is already linked with a profile. Click OK to receive a login link.</b-alert></b-modal>
    <b-modal v-model="ModalRegDone" @ok="NextRouteLogin"><b-alert variant="success" show>Success! Check your Email and follow the instructions.</b-alert></b-modal>    
  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import {fb} from '../firebase'
import { mapGetters } from "vuex";


Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
      // Input form variables
      InpForm: {
      Troupe_name: '',
      Prog_Name: '',
      TroupeType: 'default',
      troupe_address: '',
      troupe_cntct1: '',
      troupe_cntct2: '',
      troupe_email: '',
      troupe_website: '',
      troupe_facebook: '',
      troupe_instagram: '',
      troupe_youtube: '',
      troupe_twitter: '',
      troupe_description: '',
      troupe_programs: '',
      troupe_artists: '',  
      troupe_activities: '',  
      troupe_achievement: '',  
      premium_ind: 'N',  
      Video1: '',   
      Video2: '',   
      Brochure: '',   
      Biodata: '',   
      },
      ComponentKey: 0,
      ChildMainCatg: '',
      ChildCountry: '',
      ChildState: '',
      ChildCity: '',   
        RespUserEmail: null,
        RespEmailID: null,
        RespName: null,
        modalShow: false,   
        ModalRegDone: false,             
      // Firebase image upload variables
      PhotoPreview: null,
      PhotoUrl: 'https://storage.googleapis.com/thiramedia/troupes/',
      imageData: null, 
      file1: null,
      // Response array variables
      RespIns: [],
      RespGetMaxID: [ {tbl_next_seq: '0'} ],         
      // Other variables
      show: true,
      NextSeqNo: '',
      ShowSpinner: false,
      ShowPhoto: false,
      variants: ['primary', 'danger', 'warning'],
      ButtonText: 'Upload cover photo',
      }
    },
    mounted()
    {
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
        this.GetMaxID()
      },

        SetMainCatg(event) {
            this.ChildMainCatg = event
            // console.log('ChildMainCatg',this.ChildMainCatg)
        },      

        SetCountry(event) {
            this.ChildCountry = event
            // console.log('ChildCountry',this.ChildCountry)
        },

        SetState(event) {
            this.ChildState = event
            // console.log('ChildState',this.ChildState)
        },

        SetCity(event) {
            this.ChildCity = event
            // console.log('ChildCity',this.ChildCity)
        },          

      GetMaxID(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'troupe_profile_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetMaxID',REQformData)
        .then((resp)=>{
        this.RespGetMaxID=resp.data.GetMaxID;
        const FirstObject = this.RespGetMaxID[0];
        this.NextSeqNo = FirstObject.tbl_next_seq;
        // console.log('MaxSeqNo',this.NextSeqNo)
        this.InsTroupeProfile()
        this.onUpload()
      })
      },   
          
      InsTroupeProfile(){  
        this.ComponentKey=0;
        const REQformData = new FormData();
        REQformData.append('seq_no', this.NextSeqNo);
        REQformData.append('troupe_name', this.InpForm.Troupe_name);
        REQformData.append('troupe_pgm_name', this.InpForm.Prog_Name);
        REQformData.append('troupe_type', 'Troupe');
        REQformData.append('catg_main', this.ChildMainCatg);
        REQformData.append('troupe_address', this.InpForm.troupe_address);
        REQformData.append('prof_country', this.ChildCountry);
        REQformData.append('prof_state', this.ChildState);
        REQformData.append('prof_city', this.ChildCity);
        REQformData.append('troupe_cntct1', this.InpForm.troupe_cntct1);
        REQformData.append('troupe_cntct2', this.InpForm.troupe_cntct2);
        REQformData.append('troupe_email', this.InpForm.troupe_email);
        REQformData.append('troupe_website', this.InpForm.troupe_website);
        REQformData.append('troupe_facebook', this.InpForm.troupe_facebook);
        REQformData.append('troupe_instagram', this.InpForm.troupe_instagram);
        REQformData.append('troupe_youtube', this.InpForm.troupe_youtube);
        REQformData.append('troupe_twitter', this.InpForm.troupe_twitter);
        REQformData.append('troupe_photo', this.PhotoUrl + this.NextSeqNo + '/image1.jpeg');
        REQformData.append('troupe_description', this.InpForm.troupe_description);
        REQformData.append('troupe_programs', this.InpForm.troupe_programs);
        REQformData.append('troupe_artists', this.InpForm.troupe_artists);
        REQformData.append('troupe_activities', this.InpForm.troupe_activities);
        REQformData.append('troupe_achievement', this.InpForm.troupe_achievement);
        REQformData.append('premium_ind', this.InpForm.premium_ind);
        REQformData.append('Video1', this.InpForm.Video1);
        REQformData.append('Video2', this.InpForm.Video2);
        REQformData.append('Brochure', this.InpForm.Brochure);
        REQformData.append('Biodata', this.InpForm.Biodata);

        // Logic to assign profile owner
        if (this.user.authenticated) {REQformData.append('created_by', this.user.data.email)}
        else if (this.InpForm.troupe_email != '') {REQformData.append('created_by', this.InpForm.troupe_email)}
        else {{REQformData.append('created_by', 'info@thiraseela.com')}}

        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=InsTroupeProfile',REQformData)
        .then((resp)=>{
          this.RespIns=resp.data.message;
          alert(this.RespIns)
          this.ClearForms() 
          })
        .catch(error => {
          alert(error)
          })
      },
       
     ClearForms(){
       if (this.user.authenticated) {this.$router.replace('/user/dashboard')}
        this.InpForm.Troupe_name = '';
        this.InpForm.Prog_Name = '';
        this.InpForm.TroupeType = 'default';
        this.InpForm.TroupeCatgMain = 'default';
        this.InpForm.troupe_address = '';
        this.InpForm.troupe_cntct1 = '';
        this.InpForm.troupe_cntct2 = '';
        this.InpForm.troupe_email = '';
        this.InpForm.troupe_website = '';
        this.InpForm.troupe_facebook = '';
        this.InpForm.troupe_instagram = '';
        this.InpForm.troupe_youtube = '';
        this.InpForm.troupe_twitter = '';
        this.InpForm.troupe_description = '';
        this.InpForm.troupe_programs = '';
        this.InpForm.troupe_artists = '';  
        this.InpForm.troupe_activities = '';  
        this.InpForm.troupe_achievement = '';   
        this.InpForm.premium_ind = '';    
        this.InpForm.Video1 = '';   
        this.InpForm.Video2 = '';   
        this.InpForm.Brochure = '';   
        this.InpForm.Biodata = '';
        this.imageData=null;
        this.file1=null;
        // this.ShowSpinner=false;
        // this.ShowPhoto=false;
        this.ComponentKey=1;
        // this.ButtonText='Upload cover photo';
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
        this.show = true
        })
      },

// Firebase image upload methods
      click1() {
        this.$refs.input1.click()   
      },
      
      previewImage(event) {
        // this.ShowSpinner=true;
        this.uploadValue=0;
        this.PhotoPreview=null;
        this.imageData = event.target.files[0];
        // this.ButtonText='Submit to upload...'
      },

      onUpload(){
        this.PhotoPreview=null;
        // const storageRef=fb.storage().ref(`${this.imageData.name}`).put(this.imageData);
        const storageRef=fb.storage().ref().child('troupes/' + this.NextSeqNo +'/' + 'image1.jpeg').put(this.imageData);
        storageRef.on(`state_changed`,snapshot=>{
        this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
          }, error=>{console.log(error.message)},
        ()=>{this.uploadValue=100;
            storageRef.snapshot.ref.getDownloadURL().then(()=>{
                // this.ShowSpinner=false;
                // this.PhotoPreview =url;
                // this.ShowPhoto=true;
                // console.log(this.PhotoPreview)
              });
            }      
          );
       },

      SendLoginLink(){
        this.SignInLinkToEmail(this.RespEmailID);
      },

      NextRouteLogin() {
        this.$router.replace('/')
      },      
      
      SignInLinkToEmail(InpUserEmail1) {
      var actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be in the authorized domains list in the Firebase Console.
        url: 'https://thiraseela.com/user/confirm-login',
        // url: 'https://thiraseela.com/user/SetUserPswd', 
        // This must be true.
        handleCodeInApp: true,
      };        
        fb.auth().sendSignInLinkToEmail(InpUserEmail1, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', InpUserEmail1);
          this.ModalRegDone=true
        })
        .catch(() => {
          console.log('Error!!')
        });
      }, 
      FindNewUserEmailID() {
        const REQformData = new FormData();
        REQformData.append('email_id', this.InpForm.troupe_email);          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=FindNewUserEmailID',REQformData)
        .then((resp)=>{
        this.RespUserEmail=resp.data.FindNewUserEmailID;
        const FirstObject = resp.data.FindNewUserEmailID[0];
        this.RespEmailID = FirstObject.user_email;
        this.RespName = FirstObject.user_name;
        this.modalShow=true;
      })
      .catch(() => {
          console.log('Email ID not found!!')
        });
      },             
    },

  computed: {
    // map `this.user` to `this.$store.getters.user` 
    ...mapGetters({
      user: "user"
    })
    }  
  }
</script>

